<script>
export default {
	name: 'SimpleLayout'
}
</script>

<template>
	<div>
		<div>SIMPLE HEADER</div>
		<div>
			<router-view></router-view>
		</div>
		<div>SIMPLE Footer</div>
	</div>
</template>